import React, { useState } from "react"
import { graphql, Link } from "gatsby"
import Layout from "../components/layout"
import Event from "../components/Cards/event"
import styled from "@emotion/styled"
import { Subheading, Paragraph, Header1 } from "../components/headings"
import Banner from "../components/banner"
import ContentContainer from "../components/contentContainer"
import FluidContainer from "../components/fluidContainer"
import moment from "moment-timezone"
import SEO from "../components/seo"

const Dropdown = styled.select`
  background-color: white;
  border-radius: 5px;
  padding: 0.3rem 1rem;
`

const ContestsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  ul {
    margin: 0;
  }
  li {
    list-style: none;
  }
  @media (max-width: 768px) {
    display: block;
  }
`

const ListContainer = styled.div`
  width: 45%;
  @media (max-width: 768px) {
    width: 100%;
  }
`

const arrayOfYears = (start = 2002, stop) => {
  const years = []
  for (let i = start; i <= stop + 1; i++) {
    years.push(i)
  }

  return years
}

const createListItem = document => (
  <li key={document.node.id}>
    <Event {...document.node} />
  </li>
)

const sortDataIntoLists = data => {
  const hasHappened = []
  const hasNotHappened = []

  data.forEach(document => {
    const listItem = createListItem(document)

    const eventDate = document.node.date
    if (moment().isAfter(moment(eventDate), "day")) {
      return hasHappened.push(listItem)
    }

    return hasNotHappened.push(listItem)
  })

  return [hasHappened, hasNotHappened]
}

const ContestsPage = ({ data }) => {
  const [filterByRegistration, setFilterByRegistration] = useState(false)
  const [list, setList] = useState(data.allStrapiContests.edges)
  let [hasHappened, hasNotHappened] = sortDataIntoLists(list)

  if (filterByRegistration) {
    hasHappened = []
    console.log(hasNotHappened)
    hasNotHappened = hasNotHappened.filter(
      l => l.props.children.props.forceRegistrationOpen
    )
  }

  const fetchNewData = year => {
    const start = year + "-01-01"
    const end = Number(year) + 1 + "-01-01"
    return fetch(
      `${
        process.env.GATSBY_API_URL
      }/contests?date_gt=${start}&date_lt=${end}&_sort=date:DESC`,
      {
        method: "GET",
      }
    )
      .then(resp => resp.json())
      .then(resp => {
        /* strapi's query overwrites two queries, so no in between date querying for now */
        return !!resp.length
          ? resp.filter(r => {
              return new Date(r.date) < new Date(end)
            })
          : []
      })
      .then(resp => {
        const withNode = resp.map(r => {
          const offset = moment(r.date).utcOffset()
          return {
            node: {
              ...r,
              date: moment(r.date)
                .zone(offset)
                .format("MM/DD/YYYY"),
            },
          }
        })
        setList(withNode.filter(n => !n.node.hide))
      })
  }

  return (
    <Layout>
      <SEO title="Contests" keywords={[`eating`, `contests`, `official`]} />
      <Banner img={data.placeholderImage.childImageSharp.fluid}>
        <Header1>Eating Contests</Header1>
        <Paragraph>
          It has been said that if Major League Eating had a sun, it would rise
          and set on the Fourth of July in Coney Island. While the Nathan’s
          Famous finals are our Masters, our World Cup, our Super Bowl, MLE
          sanctions events all year long. Whether it’s oysters in New Orleans or
          wings in Buffalo, there’s always an upcoming contest. Keep an eye on
          this page to stay abreast of MLE’s ever-growing circuit.
        </Paragraph>
      </Banner>
      <FluidContainer>
        <ContentContainer>
          <div style={{ fontFamily: "roboto" }}>
            Filters
            <div>
              <Dropdown onChange={e => fetchNewData(e.target.value)}>
                <option selected disabled>
                  Select Year
                </option>
                {arrayOfYears(2002, moment().year())
                  .reverse()
                  .map(year => (
                    <option value={year}>{year}</option>
                  ))}
              </Dropdown>
              &nbsp;
              <Dropdown
                onChange={e =>
                  setFilterByRegistration(
                    e.target.value === "registration-open"
                  )
                }
              >
                <option selected>Show all</option>
                <option value="registration-open">
                  Show only registration open
                </option>
              </Dropdown>
            </div>
          </div>
          <br />
          <ContestsContainer>
            {!!hasNotHappened.length && (
              <ListContainer style={{ order: "2" }}>
                <Subheading>Future Events</Subheading>
                <ul>{hasNotHappened.reverse()}</ul>
              </ListContainer>
            )}
            <ListContainer style={{ order: "1" }}>
              <Subheading>Past Events</Subheading>
              {!!hasHappened.length && <ul>{hasHappened}</ul>}
              {!hasHappened.length && (
                <p style={{ fontFamily: "roboto" }}>No contest found!</p>
              )}
            </ListContainer>
          </ContestsContainer>
        </ContentContainer>
      </FluidContainer>
    </Layout>
  )
}

export default ContestsPage

export const pageQuery = graphql`
  query ContestsQuery {
    allStrapiContests(
      limit: 50
      sort: { fields: [date], order: [DESC] }
      filter: { hide: { ne: true } }
    ) {
      edges {
        node {
          id
          name
          prize
          discipline
          winner
          city
          state
          sn
          forceRegistrationOpen
          date(formatString: "MM/DD/YYYY")
          logo {
            image {
              url
            }
          }
        }
      }
    }
    placeholderImage: file(relativePath: { eq: "contest-banner.png" }) {
      childImageSharp {
        fluid(maxWidth: 500) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`
