import React from "react"
import styled from "@emotion/styled"
import placeholder from "../../images/logo.png"
import { Link } from "gatsby"
import { PrimaryButton } from "../buttons"

const Card = styled.div`
  display: flex;
  width: 100%;
  background-color: #e8ebed;
  font-family: roboto;
  box-shadow: 1px 3px 15px black;

  @media (max-width: 450px) {
    display: block;
  }
`

const Image = styled.div`
  flex-basis: 40%;
  position: relative;
  display: flex;
  align-items: center;
  img {
    z-index: 1;
    max-height: 150px;
    width: auto;
    margin: 0 auto;
  }
  &::before {
    content: "";
    z-index: 0;
    width: 100%;
    height: 100%;
    position: absolute;
    background: ${props =>
      props.image ? `url(${props.image})` : `url(${placeholder})`};
    opacity: 0.1;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;

    @media (max-width: 450px) {
      display: none;
    }
  }
  @media (max-width: 450px) {
    display: block;
    text-align: center;
  }
`

const Content = styled.div`
  background-color: #15354c;
  color: #e8ebed;
  flex-basis: 60%;
  z-index: 1;
  padding: 1rem;
  .mobile-date {
    display: none;
  }
  h2 {
    font-size: 0.9em;
    border-bottom: dashed 1px #e8ebed;
    padding-bottom: 1rem;
  }
  p {
    font-weight: 300;
    line-height: 1rem;
    font-size: 0.8rem;
    margin-bottom: 0.3rem;
  }
  @media (max-width: 450px) {
    flex-basis: 100%;
    .mobile-date {
      display: block;
    }
  }
`

const DateContainer = styled.div`
  position: absolute;
  background-color: #b5232c;
  left: 0;
  top: 10px;
  padding: 0.3rem;
  width: 140px;
  font-weight: 600;
  @media (max-width: 450px) {
    width: 100%;
    position: unset;
  }
`

const Event = ({
  name,
  logo,
  date,
  prize,
  discipline,
  id,
  sn,
  winner,
  city,
  state,
}) => {
  const image = logo && logo.image && logo.image.url
  return (
    <Card>
      <Image image={image ? image : placeholder}>
        <img src={image ? image : placeholder} />
        <DateContainer>{date}</DateContainer>
      </Image>
      <Content>
        <h2>{name}</h2>
        <p>
          <strong>Discipline:</strong> {discipline}
        </p>
        <p>
          <strong>Prizes:</strong> {prize}
        </p>
        <p>
          <strong>Location:</strong> {city}, {state}
        </p>
        {winner && (
          <p>
            <strong>Winner:</strong> {winner}
          </p>
        )}
        <p className="mobile-date">
          <strong>Date:</strong> {date}
        </p>
        <div style={{ marginTop: "2rem", textAlign: "center" }}>
          <Link to={`/contests/${sn}`}>
            <PrimaryButton>Details</PrimaryButton>
          </Link>
        </div>
      </Content>
    </Card>
  )
}

export default Event
